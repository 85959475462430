.galleryPlayers {
  overflow: hidden;
  width: 100%;
}

.galleryPlayers_wrapper {
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  cursor: pointer;
}

.galleryPlayers_wrapper::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

.galleryPlayers_wrapper .galleryPlayers_card {
  flex: 0 0 auto;
  width: 250px;  /* Adjust image size as needed */
  margin-right: 3em;  /* Space between images */
  height: 330px;
  border-radius: 1.2em;
  box-sizing: border-box !important;
  position: relative;
  border: 0px solid var(--primary-color);
}

.galleryPlayers_imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  filter: grayscale(1);
}

.galleryPlayers_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
  z-index: 1;
}

.galleryPlayers_image.loading {
  opacity: 0;
}

.galleryPlayers_image.loaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.galleryPlayers_content {
  position: absolute;
  bottom: 0em;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.galleryPlayers_placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.galleryPlayers_card.galleryPlayers_selected .galleryPlayers_imageContainer {
  filter: none;
}

.galleryPlayers_wrapper .galleryPlayers_card:hover {
  border: 4px solid var(--primary-color);
  transition: border 100ms ease;
}

.galleryPlayers_wrapper .galleryPlayers_card:hover h3 {
  font-size: 2em !important;
  transition: font-size 200ms;
}

.galleryPlayers_card h3 {
  z-index: 1;
  padding: 0.5em;
  pointer-events: none;
}

.galleryPlayers_shadow {
  position: absolute;
  border-radius: 1em;
  z-index: 0;
  top: 0px;
  left: 0px;
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
}

@media (max-width: 1240px) {
  .galleryPlayers {
    margin: 1em;
  }

  .galleryPlayers_wrapper .galleryPlayers_card {
    height: 260px;
    width: 200px;
  }
}