/* General */

h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}

.page {
  background: var(--black-color);
  width: 100%;
  overflow: hidden;
}
.page_container {
  color: var(--white-color);
  max-width: 1240px;
  margin: 0 auto;
}

@media (max-width:1240px) {
  .page_container {
    margin: 0em 0.5em;
  }
}

/* General END*/

/* INTRO */
.intro {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.intro_crosses {
  position: absolute;
  bottom: -10em;
  left: -10em;
}

.intro_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.intro_logo img {
  width: 300px;
  pointer-events: none;
  margin-bottom: 4em;
}

.intro_lang {
  display:flex;
  list-style-type: none;
  justify-content: space-evenly;
  margin-top: 2em;
  gap: 100px;
}

.intro_lang li {
  font-size: 0.8em; 
  cursor: pointer;
  text-align: center;
}

.intro_lang li a {
  text-decoration: none;
  color: var(--white-color);
}

.intro_lang li a:hover {
  opacity: 0.9;
}

.intro_lang li img {
  width: 130px;
  margin-bottom: 1em;
}

@media screen and (max-width: 992px) {
  .intro_content {
    justify-content: flex-start;
  }
  .intro_logo img {
    width: 200px;
    margin-bottom: 2em;
    margin-top: 5em;
  }

  .intro_lang {
    gap: 20px;
  }

  .intro_lang li {
    font-size: 0.7em;
  }

  .intro_lang li img {
    width: 75px;
  }

  .intro_crosses {
    bottom: -8em;
    left: -10em;
  }

  .intro_crosses img {
    width: 500px;
  }
}
/* INTRO END */

/* Navbar */

.navbar {
  background-color: var(--black-color);
  padding: 1.5em 3em !important;
  justify-content: center !important;
  align-items: center !important;
}

.navbar_container {
  max-width: 1280px;
  max-height: 70px;
}

.navbar_links {
  color: var(--white-color);
}

.navbar_links li:hover {
  transform: scale(1.1);
  transition: transform ease 200ms;
}

.navbar_link {
  font-size: 16px;
  transition: color .2s ease-in-out;
  text-decoration: none;
}

/* .navbar_link:hover {
  color: var(--secondary-color) !important;
} */

.navbar_social {
  margin-right: 1em;
}
.navbar_signup {
  margin: 0px 16px;
}

.navbar_mobile {
  width: 100% !important;
  transition: all 0.5s ease;
  position: relative;
  z-index: 10;
}



.navbar_mobile_btn {
  width: 26px;
  height: 26px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.navbar_mobile_btn_burger {
  width: 20px;
  height: 2px;
  background: var(--white-color);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.navbar_mobile_btn_burger::after,
.navbar_mobile_btn_burger::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background: var(--white-color);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.navbar_mobile_btn_burger::after {
  transform: translateY(6px);
}

.navbar_mobile_btn_burger::before {
  transform: translateY(-6px);
}

@media (max-width: 992px) {
  .navbar {
    padding: 0em !important;
  }

  .navbar_container {
    display: none !important;
  }

  .navbar_mobile_logo {
    padding: 1em !important;
  }

  .navbar_mobile_btn {
    margin-right: 1em;
  }
}
@media (min-width: 993px) {
  .navbar_mobile {
    display: none !important;
  }
}

/* btn animation */

.navbar_mobile_btn.open .navbar_mobile_btn_burger {
  transform: translateX(-30px);
  background: transparent;
  box-shadow: none;
}

.navbar_mobile_btn.open .navbar_mobile_btn_burger::before {
  transform: rotate(45deg) translate(20px, -20px);
}

.navbar_mobile_btn.open .navbar_mobile_btn_burger::after {
  transform: rotate(-45deg) translate(20px, 20px);
}

@media screen and (max-width: 992px) {
  .navbar_mobile_links {
    position: absolute;
    top: 70px;
    background: var(--black-color);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .navbar.open .navbar_mobile_links {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    height:60vh;
  }
  .navbar_mobile_links .navbar_mobile_links_list {
    flex-direction: column;
    width: 100%;
    position: relative;
    justify-content: flex-start;
    padding: 2em;
  }

  .navbar_mobile_links .navbar_mobile_links_list::before {
    position: absolute;
    content: "";
    width: 100%;
    border-radius: 5px;
    height: 1px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    background: rgb(66, 66, 69);
  }

  .navbar_mobile_links .navbar_mobile_links_list a {
    padding: 10px 0px;
    font-size: var(--description-size);
    width: 100%;
    color: var(--nav-color);
    opacity: 0.8;
  }

  .navbar_mobile_links .navbar_mobile_links_list a:hover {
    color: var(--white-color);
    opacity: 1;
  }

  .navbar_mobile_links .navbar_mobile_links_list li {
    position: relative;
    padding: 10px;
    padding-left: 0px;
  }

  .navbar_mobile_links .navbar_mobile_links_list li::after {
    position: absolute;
    content: "";
    width: 100%;
    border-radius: 5px;
    height: 1px;
    left: 0;
    bottom: 0;
    background: rgb(66, 66, 69);
  }
  .navbar .navbar_mobile_social {
    display: none;
  }

  .navbar .navbar_mobile_language {
    display: none;
  }

  .navbar.open .navbar_mobile_language {
    display: block;
  }

  .navbar.open .navbar_mobile_social {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
}

/* Navbar END */

/* Home */

.home {
  color: var(--white-color);
}
.home_banner_container {
  text-align: center;
}
.home_banner_container img {
  width: 100%;
  max-width: 1400px;
}
.home main {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 3em;
}

.home main section {
  margin: 5em 0em;
}

.home h2 {
  font-size: 1.8em !important;
  margin: 2em 0em 1em 0em !important;
}

.home h3 {
  font-size: 1.3em !important;
}

.home p {
  font-weight: 500;
}

.home_main_hr {
  height: 6px;
  width: 100px;
  background: var(--primary-color);
  margin: 2em 0em;
}

.home_why_list_item {
  display:flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 1em;
  margin: 1em 0 !important;
  box-sizing: border-box !important;
}

.home_why_list img {
  margin-bottom: 1em;
}

.home_why_list h3 {
  margin: 0.5em 0;
}

.home_why_list p {
  font-size: 0.9em !important;
}

.home_community_list {
  margin: 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.home_community_list_item {
  padding: 1em;
  display:flex;
  max-width: 320px;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.home_community_list_item h4 {
  font-size: 1em !important;
  margin: 1em;
}

.home_community_list_item p {
  font-size: 0.8em !important;
}

.home_cta {
  background-color: var(--secondary-color);
}

.home_cta_container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 3em;
  text-align: center;
}

.home_cta_container h2,
.home_cta_container h3 {
  margin: 0em 0em 1em 0em !important;
}

@media (max-width: 992px) {
  .home main {
    padding: 2em 2em 0em 2em !important;
  }

  .home .home_cta_container {
    padding: 3em 1em !important;
  }

  .home_cta_container h2,
  .home_cta_container h3 {
    font-size: 1.4em !important;
  }
}

/* Home END */

.banner_cta_container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 3em;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .banner_cta_container {
    padding: 3em 1em !important;
    flex-direction: column;
    gap: 2em;
  }

  .banner_cta_container h2 {
    font-size: 1.4em !important;
  }

  .banner_cta_container button {
    font-size: 1em !important;
  }
}

/* Footer */

.footer {
  background: var(--gray-color);
}

.footer_container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 3em;
}

.footer_contact p {
  margin: 1em 0em 0.5em 0em !important;
  font-size: 0.9em;
}

.footer_contact a {
  text-decoration: none;
  color: var(--white-color);
  font-size: 0.8em;
}

.footer_contact a:hover {
  color: var(--secondary-color);
}

.footer_links h6 {
  font-size: 0.9em !important;
  text-align: center;
  margin: 0;
}

.footer_links ul {
  list-style: none;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  padding-top: 1em;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  font-size: 0.9em !important;

}

.footer_links li {
  width: 25%;
  margin: 0.5em 0;
}

.footer_links li:hover .footer_links_link {
  font-size: 1em !important;
  transition: font-size ease 200ms;
}

.footer_links_link {
  text-decoration: none;
  font-size: 0.9em !important;
}

/* .footer_links_link:hover {
  color: var(--secondary-color) !important;
} */

.footer_hr {
  height: 1px;
  border-radius: 1px;
  background-color: gray;
  width: 100%;
  margin: 1.5em 0em;
}

.footer_copy {
  text-align: right;
  font-size: 0.9em !important;
}

@media (max-width: 992px) {
  .footer_container {
    padding: 3em 1em !important;
  }

  .footer_contact {
    text-align: center;
    width: 100%;
    margin-bottom: 2em !important;
  }

  .footer_links {
    width: 100%;
    margin: 1em 0 3em 0 !important;
    text-align: center;
  }
  .footer_links h6 {
    margin-bottom: 0.5em;
  }

  .footer_links ul {
    padding-top: 0 !important;
    flex-direction: column;
    align-items: center;
  }

  .footer_newsletter {
    text-align: center;
    width: 100%;
    padding: 0 1em;
  }

  .footer_social,
  .footer_social ul {
    text-align:center;
    width: 100% !important;
    justify-content: center !important;
    margin-bottom: 1em !important;
  }
  
  .footer_social img {
    width: 32px;
  }

  .footer_copy {
    text-align: center;
    width: 100%;
    font-size: 0.7em !important;
  }
}

/* Footer END */

/* Team */

.team_paragraph {
  font-weight: 400;
  text-align: center;
  padding: 2em 0em;
}

@media (max-width: 992px) {
  .team_paragraph {
    padding: 2em 1.5em;
  }
}


/* Team END*/

/* FAQ */

.faq {
  padding: 3em 0;
}

.faq_accordion {
  margin-bottom: 0.5em;
}

.faq_accordion.Mui-expanded {
  margin: inherit;
}

.faq_icon {
  margin-right: 0.6em;
}

/* FAQ END*/

/* Signup */

.signup {
  color: var(--white-color)
}

.signup > div > div {
  width: 100%;
}

/* Signup END */

/* Trips */

.trip_image {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.trip_image.loaded {
  opacity: 1;
}

.trip_image.loading {
  opacity: 0;
  display: none;
}

/* Trips END*/

/* --- Components UI --- */

/* buttonInscribite */

.buttonInscribite:hover {
  color: var(--primary-color) !important;
  background-color: var(--white-color) !important;
  transition: color background-color .2s ease-in-out;
}

@media (max-width: 992px) {
  .buttonInscribite {
    font-size: 10px !important;
    font-weight: 500 !important;
  }

  .buttonInscribite.MuiButton-sizeLarge {
    font-size: 0.9em !important;
    padding: 0.5em 4em 0.5em 4em !important;
    border-radius: 1em !important;
  }
}

/* buttonInscribite END */

/* language_button */

.language_button {
  border:none;
  background-color: transparent;
}

/* language_button END */

.spin {
  width: 200px;
  border-radius: 100%;
  animation: spin 3s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}